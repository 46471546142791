import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  Box,
  Tooltip,
  Checkbox,
  Tab,
  Stack,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useHistory, useLocation } from 'react-router-dom';
import { BiSearch, BiEdit, BiTrash } from 'react-icons/bi';
import { AiOutlineMail, AiOutlineWhatsApp } from 'react-icons/ai';
import { MdOutlineWorkOutline } from 'react-icons/md';
import { useQuery } from 'react-query';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { RxOpenInNewWindow } from 'react-icons/rx';
import Table from '../../components/Table';
import colors from '../../styles/colors';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import api from '../../api';
import { exportData } from './export';
import StoreContext from '../../components/Store/Context';
import ErrorModal from '../../components/ErrorModal';
import Visualization from '../../components/Visualizations/Time';

import { Container, Label, Card, Group, Title, TabLabel } from './styles';
import { RemoveUserFromCompanyDialog } from './RemoveUserFromCompanyDialog';
import { CompanySelector } from '../../components/CompanySelector';
import { listAllCompanies } from '../../api/companies';
import { EditCollaboratorDialog } from '../../components/EditCollaboratorDialog';
import { EditCareerDialog } from '../../components/EditCareerDialog';

function TeamDashboard() {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [error, setError] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [allData, setAllData] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [arrayUser, setArrayUser] = useState([]);
  const [msgError, setMsgError] = useState('');
  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const [jobSelected, setJobSelected] = useState(0);
  const [job, setJob] = useState([]);
  const history = useHistory();
  const [openRemoveUser, setOpenRemoveUser] = useState();
  const [userToRemove, setUserToRemove] = useState(0);
  const [initialCompany, setInitialCompany] = useState({
    id: 0,
    razao_social: '',
  });
  const [tab, setTab] = useState('1');
  const [collaborators, setCollaborators] = useState([]);
  const [fullList, setFullList] = useState([]);
  const [fullCollaboratorList, setFullCollaboratorList] = useState([]);
  const [openEditCollaborator, setOpenEditCollaborator] = useState(false);
  const [selectedUser, setSelectedUser] = useState(0);
  const [openEditCareer, setOpenEditCareer] = useState(false);
  const [term, setTerm] = useState('');

  const location = useLocation();

  const { data: allCompanies } = useQuery('listAllCompanies', listAllCompanies);

  const getUsers = useCallback(async () => {
    await api
      .get('/users?status=S', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        setArrayUser(response.data.users);
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  const getData = useCallback(async () => {
    if (selectedCompany > 0) {
      setIsLoading(true);
      try {
        const response = await api.post(
          '/reports/igrowntime',
          {
            company_id: selectedCompany,
          },
          {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          },
        );
        let igrownData;
        if (jobSelected) {
          igrownData = response.data.data.filter(
            item => item.carreira_id === jobSelected,
          );
        } else {
          igrownData = response.data.data;
        }
        if (igrownData.length === 0)
          throw new Error('Não há colaboradores para esta carreira!');
        const aux = igrownData.map(item => ({
          id: item.user_id ?? 1,
          name: item.nome,
          email: item.email,
          profile: item.perfil_comportamental || '-',
          culture: item.cultura || '-',
          potential: item.potencial,
          values: `${Math.round(item.valores_carreira ?? 0)}%`,
          selected: false,
        }));
        const { data: ranking } = await api.post(
          '/ranking',
          {
            emails: igrownData.map(item => item.email),
            carreira_id: jobSelected,
          },
          {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          },
        );
        const result = aux
          .map(item => ({
            ...item,
            id: item.id,
            ranking: ranking?.DATA?.find(r => r.EMAIL === item.email),
          }))
          .sort((a, b) =>
            a.ranking?.FIT_PERSONA_IDEAL > b.ranking?.FIT_PERSONA_IDEAL
              ? -1
              : 1,
          );
        setFullCollaboratorList(result);
        setAllData(igrownData);
      } catch (err) {
        setMsgError(err.message);
        setError(true);
      }
      setIsLoading(false);
    } else {
      setMsgError('É obrigatória a seleção da empresa!');
    }
  }, [jobSelected, selectedCompany, token]);

  const handleChangeCheckbox = item => {
    setData(oldData =>
      oldData.map(i => ({
        ...i,
        selected: item.email === i.email ? !i.selected : i.selected,
      })),
    );
  };

  const renderHead = (item, index) => (
    <>
      <th style={{ width: '30px' }} key={index}>
        {item.checkbox}
      </th>
      <th key={index}>{item.name}</th>
      <th key={index} style={{ width: '48px' }}>
        {item.ranking}
      </th>
      <th key={index} style={{ width: '180px' }}>
        {item.fit}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.profile}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.culture}
      </th>
      <th
        className={width <= 570 ? 'hidden' : ''}
        style={{ width: '140px' }}
        key={index}
      >
        {item.values}
      </th>
      <th key={index}>{item.button}</th>
      <th key={index}> </th>
    </>
  );

  const tooltip = item => (
    <Box sx={{ padding: '8px', fontSize: '14px' }}>
      <Typography sx={{ mb: 1, fontSize: '15px' }}>
        <strong>Detalhes do Fit</strong>
      </Typography>
      <p>
        Global:{' '}
        <strong>{Math.round(item.ranking?.FIT_PERSONA_IDEAL ?? 0)}%</strong>
      </p>
      <p>
        Comportamento:{' '}
        <strong>{Math.round(item.ranking?.FITS.COMPORTAMENTAL ?? 0)}%</strong>
      </p>
      <p>
        Learn Agilities:{' '}
        <strong>{Math.round(item.ranking?.FITS.LEARN_AGILITIES ?? 0)}%</strong>
      </p>
      <p>
        Cultural:{' '}
        <strong>{Math.round(item.ranking?.FITS.CULTURAL ?? 0)}%</strong>
      </p>
      <p>
        Âncoras:{' '}
        <strong>{Math.round(item.ranking?.FITS.VALORES_CARREIRA ?? 0)}%</strong>
      </p>
      <p>
        Hard Skills:{' '}
        <strong>{Math.round(item.ranking?.FITS.HARD_SKILLS ?? 0)}%</strong>
      </p>
    </Box>
  );

  const getDataSelected = useCallback(
    async (value, json) => {
      setDataSelected(
        allData.filter(item => {
          return item.email === json.email;
        }),
      );
      setDetails(value);
    },
    [allData],
  );

  const handleOpenRemoveUserDialog = item => {
    setOpenRemoveUser(true);
    setUserToRemove(item.id);
  };

  const renderBody = useCallback(
    (item, index) => (
      <tr key={index}>
        <td style={{ width: '30px' }}>
          <Checkbox
            checked={item.selected}
            onChange={() => handleChangeCheckbox(item)}
          />
        </td>
        <td>{item.name}</td>
        <td>
          <span style={{ marginRight: '2px', fontWeight: 700 }}>
            {Math.round(item.ranking?.FIT_PERSONA_IDEAL ?? 0)}%
          </span>
          <Tooltip title={tooltip(item)}>
            <InfoIcon fontSize="small" sx={{ cursor: 'pointer' }} />
          </Tooltip>
        </td>
        <td>
          <Button
            variant="outlined-green"
            sx={{ p: '0.125rem', width: '120px' }}
            onClick={() =>
              history.push('/fitColaboradores', {
                emails: [item.email],
                companyId: selectedCompany,
                careerId: jobSelected,
              })
            }
          >
            Relatório Fit
          </Button>
        </td>
        <td>
          {item.profile}&nbsp;
          {item.profile !== '-' && (
            <Tooltip title="Exibir relatório comportamental">
              <a
                target="_new"
                href={`/relatorioComportamental?activeUser=${encodeURIComponent(
                  item.email,
                )}`}
              >
                <RxOpenInNewWindow
                  style={{ color: colors.lightBlue, fontSize: '14px' }}
                />
              </a>
            </Tooltip>
          )}
        </td>
        <td>
          {item.culture}&nbsp;
          {item.culture !== '-' && (
            <Tooltip title="Exibir relatório de cultura">
              <a
                target="_new"
                href={`/relatorioDeCultura?activeUser=${encodeURIComponent(
                  item.email,
                )}`}
              >
                <RxOpenInNewWindow
                  style={{ color: colors.lightBlue, fontSize: '14px' }}
                />
              </a>
            </Tooltip>
          )}
        </td>
        <td>
          {item.values}&nbsp;
          {item.values !== '0%' && (
            <Tooltip title="Exibir relatório de âncoras de carreira">
              <a
                target="_new"
                href={`/relatorioDeValoresDeCarreira?activeUser=${encodeURIComponent(
                  item.email,
                )}`}
              >
                <RxOpenInNewWindow
                  style={{ color: colors.lightBlue, fontSize: '14px' }}
                />
              </a>
            </Tooltip>
          )}
        </td>
        <td>
          <Button
            variant="outlined"
            size="small"
            sx={{ p: '0.125rem', width: '120px' }}
            onClick={() => getDataSelected(true, item)}
          >
            Ver detalhes
          </Button>
        </td>
        <td>
          <Tooltip title="Desvincular colaborador da empresa">
            <button type="button">
              <BiTrash
                size="20"
                style={{ color: '#ff0000' }}
                onClick={() => handleOpenRemoveUserDialog(item)}
              />
            </button>
          </Tooltip>
        </td>
      </tr>
    ),
    [getDataSelected, history, jobSelected, selectedCompany],
  );

  const onCloseError = () => {
    setError(false);
  };

  const getCompanies = useCallback(
    async id => {
      if (id === 8) {
        setCompanies([]);
      } else {
        setCompanies(userGlobal);
      }
    },
    [userGlobal],
  );

  const onClose = () => {
    setDetails(false);
  };

  const handleEditCollaboratorDialog = item => {
    setSelectedUser(item);
    setOpenEditCollaborator(true);
  };

  const handleEditCareerDialog = item => {
    setSelectedUser(item);
    setOpenEditCareer(true);
  };

  const handleCompareSelected = useCallback(async () => {
    const selectedEmails = data
      .filter(item => item.selected)
      .map(item => item.email);
    history.push('/fitColaboradores', {
      emails: selectedEmails,
      companyId: selectedCompany,
      careerId: jobSelected,
    });
  }, [data, history, jobSelected, selectedCompany]);

  const users = {
    head: [
      {
        name: 'Nome',
        ranking: 'Ranking',
        fit: 'Fit',
        profile: 'Perfil comportamental',
        culture: 'Cultura',
        values: 'Âncoras de carreira',
        buttons: ' ',
        checkbox: ' ',
      },
    ],
    body: data,
  };

  const fieldRow0 = width <= 570 ? '60%' : 600;
  const fieldRow1 = width <= 570 ? '40%' : 400;

  const handleCompany = newValue => {
    if (newValue > 0) {
      setSelectedCompany(newValue);
      setJobSelected(0);
    }
  };

  const jsonCollaborators = {
    head: [
      {
        name: 'Nome',
        career: 'Carreira',
        profile: 'Perfil comportamental',
        culture: 'Cultura',
        values: 'Âncoras de carreira',
        contacts: 'Contatos',
        actions: 'Ações',
      },
    ],
    body: collaborators || [],
  };

  const renderHeadCollaborators = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th key={index}>{item.career}</th>
      <th key={index}>{item.profile}</th>
      <th key={index}>{item.culture}</th>
      <th key={index}>{item.values}</th>
      <th key={index}>{item.contacts}</th>
      <th key={index} style={{ textAlign: 'center' }}>
        {item.actions}
      </th>
    </>
  );

  const renderBodyCollaborators = (item, index) => {
    return (
      <tr key={index}>
        <td>{item.name}</td>
        <td>{item.career?.nome}</td>
        <td>
          {item.profile}&nbsp;
          {item.tests.comportamental && (
            <Tooltip title="Exibir relatório comportamental">
              <a
                target="_new"
                href={`/relatorioComportamental?activeUser=${encodeURIComponent(
                  item.email,
                )}`}
              >
                <RxOpenInNewWindow
                  style={{ color: colors.lightBlue, fontSize: '14px' }}
                />
              </a>
            </Tooltip>
          )}
        </td>
        <td>
          {item.culture}&nbsp;
          {item.tests.cultural && (
            <Tooltip title="Exibir relatório de cultura">
              <a
                target="_new"
                href={`/relatorioDeCultura?activeUser=${encodeURIComponent(
                  item.email,
                )}`}
              >
                <RxOpenInNewWindow
                  style={{ color: colors.lightBlue, fontSize: '14px' }}
                />
              </a>
            </Tooltip>
          )}
        </td>
        <td>
          {item.values}&nbsp;
          {item.tests.valores && (
            <Tooltip title="Exibir relatório de âncoras de carreira">
              <a
                target="_new"
                href={`/relatorioDeValoresDeCarreira?activeUser=${encodeURIComponent(
                  item.email,
                )}`}
              >
                <RxOpenInNewWindow
                  style={{ color: colors.lightBlue, fontSize: '14px' }}
                />
              </a>
            </Tooltip>
          )}
        </td>
        <td>
          <Stack direction="row" spacing={1}>
            <Tooltip title={item.email}>
              <a href={`mailto:${item.email}`}>
                <AiOutlineMail size="20" style={{ color: colors.lightBlue }} />
              </a>
            </Tooltip>
            <Tooltip title="Telefone">
              <a href="https://wa.me" target="_blank" rel="noreferrer">
                <AiOutlineWhatsApp size="20" style={{ color: '#25D366' }} />
              </a>
            </Tooltip>
          </Stack>
        </td>
        <td>
          <Stack direction="row" spacing={1}>
            <Tooltip title="Ver detalhes">
              <button
                type="button"
                onClick={() => getDataSelected(true, item)}
                style={{ color: colors.lightBlue }}
              >
                <BiSearch size="20" />
              </button>
            </Tooltip>
            {profileGlobal.perfil_id === 8 && (
              <Tooltip title="Editar colaborador">
                <button
                  type="button"
                  onClick={() => handleEditCollaboratorDialog(item)}
                >
                  <BiEdit size="20" style={{ color: colors.lightBlue }} />
                </button>
              </Tooltip>
            )}

            {profileGlobal.perfil_id === 12 && (
              <Tooltip title="Alterar carreira">
                <button
                  type="button"
                  onClick={() => {
                    handleEditCareerDialog(item);
                  }}
                >
                  <MdOutlineWorkOutline
                    size="20"
                    style={{ color: colors.lightBlue }}
                  />
                </button>
              </Tooltip>
            )}

            <Tooltip title="Desvincular colaborador da empresa">
              <button
                type="button"
                onClick={() => handleOpenRemoveUserDialog(item)}
              >
                <BiTrash size="20" style={{ color: '#ff0000' }} />
              </button>
            </Tooltip>
          </Stack>
        </td>
      </tr>
    );
  };

  const getCollaborators = useCallback(
    async id => {
      await api
        .get(`/companies/users/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data: companyUsers } = response.data;
          setFullList(
            companyUsers
              .map(user => ({
                ...user,
                career: job.find(j => j.id === user.carreira_id),
                profile: data.find(item => item.id === user.id)?.profile,
                culture: data.find(item => item.id === user.id)?.culture,
                values: data.find(item => item.id === user.id)?.values,
              }))
              .sort((a, b) => (a.name < b.name ? -1 : 1)),
          );
          setCollaborators(
            companyUsers
              .map(user => ({
                ...user,
                career: job.find(j => j.id === user.carreira_id),
                profile: data.find(item => item.id === user.id)?.profile,
                culture: data.find(item => item.id === user.id)?.culture,
                values: data.find(item => item.id === user.id)?.values,
              }))
              .sort((a, b) => (a.name < b.name ? -1 : 1)),
          );
        });
    },
    [data, job, token],
  );


  useEffect(() => {
    if (profileGlobal.perfil_id) {
      getCompanies(profileGlobal.perfil_id);
      getUsers();
    }
    setWidth(window.screen.width);
  }, [getCompanies, getUsers, profileGlobal, width]);

  useEffect(() => {
    if (selectedCompany !== 0) {
      api
        .get(`/companies/carreiras/${selectedCompany}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(res => {
          setJob(res.data);
        });
    }
  }, [selectedCompany, token]);

  useEffect(() => {
    if (job.length) getCollaborators(selectedCompany);
  }, [getCollaborators, job.length, selectedCompany]);

  useEffect(() => {
    if (location.state?.careerId && location.state?.companyId) {
      setSelectedCompany(location.state.companyId);
      setJobSelected(location.state.careerId);
      const currentCompany = allCompanies.find(
        c => c.id === location.state.companyId,
      );
      setInitialCompany(currentCompany);
      window.history.replaceState({}, document.title);
    }
  }, [allCompanies, location.state]);

  useEffect(() => {
    if (selectedCompany) getData();
  }, [getData, jobSelected, selectedCompany]);

  useEffect(() => {
    if (jobSelected) setTab('2');
  }, [jobSelected]);


  useEffect(() => {
    if (!term) {
      setCollaborators(fullList);
    } else {
      setCollaborators(
        fullList.filter(item =>
          item.name.toLowerCase().includes(term?.toLowerCase()),
        ),
      );
    }
  }, [fullList, term]);

  useEffect(() => {
    if (!term) {
      setData(fullCollaboratorList);
    } else {
      setData(
        fullCollaboratorList.filter(item =>
          item.name.toLowerCase().includes(term?.toLowerCase()),
        ),
      );
    }
  }, [fullCollaboratorList, term]);

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} msg={msgError} />
      <Visualization
        arrayUser={arrayUser}
        width={width}
        data={dataSelected}
        isOpen={details}
        onClose={onClose}
      />
      <Title>Painel do Time</Title>
      <Card>
        <Label>Filtrar sua busca</Label>
        <Group>
          <CompanySelector
            fullWidth={false}
            label="Procurar por empresa"
            width={fieldRow0}
            options={companies}
            onChange={company => handleCompany(company?.id)}
            initialState={initialCompany}
          />
          <FormControl sx={{ width: fieldRow1 }}>
            <Select
              sx={{ ml: 2, textAlign: 'left' }}
              value={jobSelected}
              onChange={e => setJobSelected(e.target.value)}
              autoWidth
              size="small"
              color="info"
            >
              <MenuItem value={0}>
                <em>Procurar por carreira</em>
              </MenuItem>
              {job.length > 0
                ? job.map(item => {
                    return <MenuItem value={item.id}>{item.nome}</MenuItem>;
                  })
                : false}
            </Select>
          </FormControl>
        </Group>
      </Card>
      {isLoading && (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '160px' }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Box sx={{ maxWidth: '1280px', marginTop: 20, marginX: 'auto' }}>
          <TabContext value={tab}>
            <TabList onChange={(event, newValue) => setTab(newValue)}>
              <Tab
                label={<TabLabel>Todos os Colaboradores</TabLabel>}
                value="1"
              />
              <Tab
                label={<TabLabel>Fit de Colaboradores</TabLabel>}
                value="2"
                disabled={!jobSelected}
              />
            </TabList>
            <TabPanel value="1" sx={{ padding: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '12px',
                  marginTop: '12px',
                }}
              >
                <FormControl
                  size="small"
                  sx={{
                    minWidth: '280px',
                    width: '600px',
                    marginRight: '8px',
                  }}
                >
                  <TextField
                    size="small"
                    label="Pesquise por nome"
                    value={term}
                    onChange={e => setTerm(e.target.value)}
                  />
                </FormControl>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="body1">Exportar:</Typography>
                  <Button 
                    size="small"
                    variant="outlined-green"
                    disabled={isLoading}
                    onClick={async () => {
                      try {
                        setIsLoading(true);
                        await exportData('csv', selectedCompany, jobSelected, token);
                      } catch (error) {
                        setMsgError(error.message);
                        setError(true);
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                  >
                    {isLoading ? <CircularProgress size={20} /> : 'CSV'}
                  </Button>
                </Stack>
              </Box>
              <Table
                limit="10"
                headData={jsonCollaborators.head}
                renderHead={(item, index) =>
                  renderHeadCollaborators(item, index)
                }
                bodyData={jsonCollaborators.body}
                renderBody={(item, index) =>
                  renderBodyCollaborators(item, index)
                }
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 1 }}>
              <Grid container spacing={2} paddingY={2}>
                <Grid item xs={12} sm={6} lg={7}>
                  <FormControl fullWidth size="small">
                    <TextField
                      fullWidth
                      size="small"
                      label="Pesquise por nome"
                      value={term}
                      onChange={e => setTerm(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    variant="outlined-green"
                    onClick={handleCompareSelected}
                  >
                    Comparar selecionados
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="body1">Exportar:</Typography>
                    <Button 
                      size="small"
                      variant="outlined-green"
                      disabled={isLoading}
                      onClick={async () => {
                        try {
                          setIsLoading(true);
                          await exportData('csv', selectedCompany, jobSelected, token);
                        } catch (error) {
                          setMsgError(error.message);
                          setError(true);
                        } finally {
                          setIsLoading(false);
                        }
                      }}
                    >
                      {isLoading ? <CircularProgress size={20} /> : 'CSV'}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>

              <Table
                limit={5}
                headData={users.head}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={users.body}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </TabPanel>
          </TabContext>
        </Box>
      )}
      <RemoveUserFromCompanyDialog
        isOpen={openRemoveUser}
        onClose={() => setOpenRemoveUser(false)}
        onDelete={() => {
          getData();
          getCollaborators(selectedCompany);
        }}
        companyId={selectedCompany}
        userId={userToRemove}
      />
      <EditCollaboratorDialog
        isOpen={openEditCollaborator}
        onClose={() => setOpenEditCollaborator(false)}
        onUpdate={() => {
          getData();
        }}
        selectedUser={selectedUser}
        initialCompanyId={selectedCompany}
      />
      <EditCareerDialog
        isOpen={openEditCareer}
        onClose={() => setOpenEditCareer(false)}
        onUpdate={() => {
          getData();
        }}
        selectedUser={selectedUser}
        companyId={selectedCompany}
      />
    </Container>
  );
}

export default TeamDashboard;
