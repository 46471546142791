import api from '../../api';

export const exportData = async (format, selectedCompany, jobSelected, token) => {
  const maxRetries = 3;
  let currentRetry = 0;
  
  // Use form submission approach for CSV to bypass CORS
  if (format === 'csv') {
    return exportCsvWithFormSubmit(selectedCompany, jobSelected, token);
  }
  
  const doExport = async () => {
    const endpoint = '/reports/export/team/excel';

    const config = {
      responseType: 'blob',
      timeout: 300000, // 30 seconds timeout
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.refreshToken}`
      },
    };

    const response = await api.post(endpoint, {
      company_id: selectedCompany,
      carreira_id: jobSelected
    }, config);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'team_export_xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  try {
    while (currentRetry < maxRetries) {
      try {
        return await doExport();
      } catch (error) {
        currentRetry++;
        if (currentRetry === maxRetries) {
          throw error;
        }
        // Espera 1 segundo antes de tentar novamente
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  } catch (error) {
    console.error('Erro na exportação:', error);
    if (error.response) {
      // Erro do servidor com resposta
      const message = error.response.data?.message || 'Erro ao gerar exportação';
      throw new Error(message);
    } else if (error.request) {
      // Erro de rede sem resposta do servidor
      throw new Error('Erro de conexão. Por favor, verifique sua internet e tente novamente.');
    } else {
      // Erro na configuração da requisição
      throw new Error('Erro ao preparar exportação. Por favor, tente novamente.');
    }
  }
};

// Function to export CSV by opening a URL with token as query parameter to bypass CORS
const exportCsvWithFormSubmit = (selectedCompany, jobSelected, token) => {
  return new Promise((resolve, reject) => {
    try {
      // Create URL with query parameters including the token
      const baseUrl = `${process.env.REACT_APP_APIURL}/reports/export/team/csv`;
      
      // Create URL with query parameters
      const url = new URL(baseUrl);
      
      // Add company_id parameter
      url.searchParams.append('company_id', selectedCompany);
      
      // Add carreira_id parameter if provided
      if (jobSelected) {
        url.searchParams.append('carreira_id', jobSelected);
      }
      
      // Add authorization token parameter
      // Using 'token' as parameter name to avoid conflicts with header names
      url.searchParams.append('token', token.refreshToken);
      
      console.log('Exportando CSV com URL:', url.toString());
      
      // Open URL in new tab/window
      window.open(url.toString(), '_blank');
      
      // Resolve the promise after a short delay
      setTimeout(() => {
        resolve();
      }, 1000);
    } catch (error) {
      console.error('Erro na exportação CSV:', error);
      reject(new Error('Erro ao preparar exportação CSV. Por favor, tente novamente.'));
    }
  });
};
