import axios from 'axios';
import logger from '../utils/logger';

const api = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  // APPKEY: process.env.REACT_APP_APPKEY,
  // APPSECRET: process.env.REACT_APP_SECRET,
});

// Add a request interceptor to automatically add the token to all requests
api.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const tokenData = localStorage.getItem('token');
    logger.log('Token data from localStorage:', tokenData);
    
    if (tokenData) {
      try {
        const parsedToken = JSON.parse(tokenData);
        logger.log('Parsed token:', parsedToken);
        
        // Add the token to the Authorization header if it exists
        // The token structure might be different depending on how it's stored
        if (parsedToken) {
          // Check different possible token structures
          if (parsedToken.refreshToken) {
            config.headers.Authorization = `Bearer ${parsedToken.refreshToken}`;
            logger.log('Added Authorization header from refreshToken:', config.headers.Authorization);
          } else if (parsedToken.authenticated_user && parsedToken.authenticated_user.refreshToken) {
            config.headers.Authorization = `Bearer ${parsedToken.authenticated_user.refreshToken}`;
            logger.log('Added Authorization header from authenticated_user.refreshToken:', config.headers.Authorization);
          } else if (parsedToken.token) {
            config.headers.Authorization = `Bearer ${parsedToken.token}`;
            logger.log('Added Authorization header from token:', config.headers.Authorization);
          } else {
            logger.log('No token found in parsed token object:', parsedToken);
          }
        } else {
          logger.log('Parsed token is null or undefined');
        }
      } catch (error) {
        // If there's an error parsing the token, just continue without adding it
        logger.error('Error parsing token:', error);
      }
    } else {
      logger.log('No token found in localStorage');
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
