/**
 * Logger utility to control console logs based on environment settings
 * 
 * Usage:
 * import logger from '../utils/logger';
 * 
 * // Instead of console.log
 * logger.log('Message', data);
 * 
 * // Instead of console.error
 * logger.error('Error message', error);
 * 
 * // Instead of console.warn
 * logger.warn('Warning message');
 * 
 * // Instead of console.info
 * logger.info('Info message');
 */

const isLoggingEnabled = process.env.REACT_APP_ENABLE_CONSOLE_LOGS === 'true';
const environment = process.env.REACT_APP_ENV || 'development';

const logger = {
  /**
   * Log a message if logging is enabled
   * @param  {...any} args - Arguments to log
   */
  log: (...args) => {
    if (isLoggingEnabled) {
      console.log(...args);
    }
  },

  /**
   * Log an error message if logging is enabled
   * @param  {...any} args - Arguments to log
   */
  error: (...args) => {
    if (isLoggingEnabled) {
      console.error(...args);
    }
  },

  /**
   * Log a warning message if logging is enabled
   * @param  {...any} args - Arguments to log
   */
  warn: (...args) => {
    if (isLoggingEnabled) {
      console.warn(...args);
    }
  },

  /**
   * Log an info message if logging is enabled
   * @param  {...any} args - Arguments to log
   */
  info: (...args) => {
    if (isLoggingEnabled) {
      console.info(...args);
    }
  },

  /**
   * Get the current environment
   * @returns {string} The current environment
   */
  getEnvironment: () => {
    return environment;
  },

  /**
   * Check if the current environment is production
   * @returns {boolean} True if the current environment is production
   */
  isProduction: () => {
    return environment === 'production';
  },

  /**
   * Check if the current environment is development
   * @returns {boolean} True if the current environment is development
   */
  isDevelopment: () => {
    return environment === 'development';
  }
};

export default logger;
