import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
  Typography,
} from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import InputMask from 'react-input-mask';
import { lightTheme } from '../../styles/theme';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import logo from '../../assets/img/logo.svg';
import HeaderLogin from '../../components/Headers/HeaderLogin';
import { validEmail } from '../../utils/validEmail';
import { phoneMask } from '../../utils/phoneMask';
import { validDate } from '../../utils/validDate';
import { dateMask } from '../../utils/dateMask';
import { auth } from '../../firebaseConfig';
import api from '../../api';
import Companies from '../../assets/data/companies.json';

// UTILS
import ScrollToTop from '../../utils/ScrollToTop';

// COMPONENTS
import StoreContext from '../../components/Store/Context';

import {
  Description,
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Label,
  Container,
  Card,
  Header,
  Logo,
  Group,
  ErrorMessage,
  Row,
} from './styles';
import { useQuery } from '../../utils/searchQuery';
import { useAuth } from '../../contexts/AuthContext';
import { validateCpf } from '../../utils/validateCpf';
import { COMPANIES_WITH_REQUIRED_CPF } from '../../utils/requiredCPF';

function unmaskCpf(input) {
  return input.replace(/[._-]/g, '');
}

function NaturalPerson() {
  const { setToken } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [checkbox, setCheckbox] = useState(false);
  const [open, setOpen] = useState(false);
  const [, setOpenCareer] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState('');
  const [genre, setGenre] = useState('');
  const [occupation, setOccupation] = useState('');
  const [cpf, setCpf] = useState('');
  const [error, setError] = useState(false);
  const [statusOccupation, setStatusOccupation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusCompanie, setStatusCompanie] = useState(false);
  const [statusCareer, setStatusCareer] = useState(false);
  const [msg, setMsg] = useState('');
  const [companies, setCompanies] = useState([]);
  const [career, setCareer] = useState([]);
  const [companie, setCompanie] = useState('');
  const [companieId, setCompanieId] = useState('');
  const [careerId, setCareerId] = useState('');
  const [nameCompanie, setNameCompanie] = useState('');
  const [invalid, setInvalid] = useState(false);
  const { signIn } = useAuth();

  const history = useHistory();
  const query = useQuery();
  const redirectUrl = query.get('redirect');

  const onNext = async e => {
    e.preventDefault();
    setIsLoading(true);
    const inputName = name.trim();
    const word = inputName.split(' ');
    if (
      name.length > 0 &&
      phone.length > 0 &&
      password.length > 0 &&
      passwordConfirm.length > 0 &&
      email.length > 0 &&
      date.length > 0 &&
      genre.toString().length > 0 &&
      occupation.toString().length > 0 &&
      checkbox
    ) {
      if (
        COMPANIES_WITH_REQUIRED_CPF.includes(companie?.id) &&
        unmaskCpf(cpf).length !== 11 &&
        invalid
      ) {
        setMsg('Informe um CPF válido');
        setError(true);
        setIsLoading(false);
        return;
      }
      if (!validEmail(email.trim())) {
        setMsg('E-mail inválido! Digite um e-mail válido!');
        setError(true);
        setIsLoading(false);
      } else if (password.trim() !== passwordConfirm.trim()) {
        setMsg('A senhas informadas devem ser iguais!');
        setError(true);
        setIsLoading(false);
      } else if (word.length === 1) {
        setMsg('É necessário inserir pelo menos um sobrenome!');
        setError(true);
        setIsLoading(false);
      } else if (validDate(date)) {
        createUserWithEmailAndPassword(auth, email.trim(), password)
          // here we get user id if creating is successful.
          .then(async user => {
            const json = {
              email: email.trim(),
              name,
              password,
              password_confirmation: password,
              phone,
              uid_firebase: user.user.uid,
            };
            await api
              .post('/register', json, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then(async response => {
                const dataUser = response.data;
                const credentials = {
                  email: email.trim(),
                  password,
                };
                await api
                  .post('/login', credentials, {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(async res => {
                    const { data } = res;
                    setToken(data);

                    await signIn(credentials);

                    const perfil = {
                      user_id: dataUser.user.id,
                      perfil_id: 11,
                    };

                    await api
                      .post('/users/perfil', perfil, {
                        headers: {
                          authorization: `Bearer ${data.refreshToken}`,
                        },
                      })
                      .then(async () => {
                        const complementary = {
                          user_id: dataUser.user.id,
                          nome: word[0],
                          sobrenome: word[1],
                          tipo_pessoa: 'PF',
                          data_nascimento: date,
                          genero: genre,
                          ocupacao_principal: occupation,
                          telefone: phone,
                          cpf,
                        };
                        await api
                          .post('/dados-candidatos/save', complementary, {
                            headers: {
                              authorization: `Bearer ${data.refreshToken}`,
                            },
                          })
                          .then(async () => {
                            if (
                              nameCompanie.length > 0 &&
                              companie.toString().length > 0
                            ) {
                              const dataCompanie = {
                                cnpj: '',
                                razao_social: nameCompanie,
                                nome_fantasia: nameCompanie,
                                id_igrown: '',
                              };
                              await api
                                .post('/companies/create', dataCompanie, {
                                  headers: {
                                    authorization: `Bearer ${data.refreshToken}`,
                                  },
                                })
                                .then(async companyRes => {
                                  const resp = companyRes.data;
                                  const companyId = resp.created_id
                                    ? resp.created_id
                                    : resp.exists_id;
                                  const dataUserCompanie = {
                                    user_id: dataUser.user.id,
                                    company_id: companyId,
                                  };
                                  await api
                                    .post('/users/empresa', dataUserCompanie, {
                                      headers: {
                                        authorization: `Bearer ${data.refreshToken}`,
                                      },
                                    })
                                    .then(async () => {
                                      const messageEmail = {
                                        emails: [email.trim()],
                                        assunto:
                                          'Bem-vindo à iGrown! Comece a moldar sua jornada de carreira.',
                                        mensagem: `Caro ${name}, \n\nSeja bem-vindo à iGrown! Estamos muito felizes em recebê-lo(a) em nossa plataforma de desenvolvimento de carreira. Como um colaborador registrado, você está prestes a iniciar uma jornada única e emocionante de autodescoberta, crescimento e oportunidades. \nNa iGrown, acreditamos no seu potencial e estamos aqui para ajudá-lo(a) a acelerar sua carreira. Nossa plataforma foi projetada para fornecer insights valiosos sobre suas habilidades, agilidades de aprendizado, potencial e estilo cultural, permitindo que você descubra seu verdadeiro potencial e alcance suas metas profissionais.\nAqui estão algumas coisas que você poderá fazer na iGrown:\nTestes de Perfil Comportamental: Descubra suas habilidades e características comportamentais, compreenda suas preferências de trabalho e como você pode se desenvolver ainda mais.\nTeste Cultural: Identifique seu estilo cultural predominante e compreenda como você se encaixa na cultura organizacional, facilitando sua integração e alinhamento com a empresa.\nExplorar suas Âncoras de Carreira: Descubra o que é mais importante para você em sua jornada profissional e como suas âncoras de carreira podem orientar suas escolhas e motivações.\nA iGrown está aqui para apoiá-lo(a) em cada etapa de sua carreira. Estamos empenhados em fornecer a você uma experiência excepcional e auxiliá-lo(a) no desenvolvimento de suas habilidades e alcance de seus objetivos profissionais.\nAproveite ao máximo sua experiência na iGrown! Estamos entusiasmados por fazer parte da sua jornada de crescimento e sucesso.\nSeu sucesso é nossa prioridade. Estamos aqui para ajudá-lo(a) a alcançar grandes conquistas!\n\nAtenciosamente,\nEquipe iGrown`,
                                      };
                                      await api
                                        .post('/email/send', messageEmail, {
                                          headers: {
                                            authorization: `Bearer ${data.refreshToken}`,
                                          },
                                        })
                                        .then(async () => {
                                          history.push({
                                            pathname: '/enviarEmail',
                                            search: `?redirect=${encodeURIComponent(
                                              redirectUrl,
                                            )}`,
                                            state: {
                                              email: email.trim(),
                                            },
                                          });
                                        })
                                        .catch(() => {
                                          setMsg(
                                            'Erro ao enviar os seus dados!',
                                          );
                                          setError(true);
                                          setIsLoading(false);
                                          user.delete();
                                        });
                                    })
                                    .catch(() => {
                                      setMsg('Erro ao enviar os seus dados!');
                                      setError(true);
                                      setIsLoading(false);
                                      user.delete();
                                    });
                                })
                                .catch(() => {
                                  setMsg('Erro ao enviar os seus dados!');
                                  setError(true);
                                  setIsLoading(false);
                                  user.delete();
                                });
                            } else if (
                              nameCompanie.length === 0 &&
                              companie.toString().length > 0
                            ) {
                              const dataUserCompanie = {
                                user_id: dataUser.user.id,
                                company_id: companieId,
                              };
                              await api
                                .post('/users/empresa', dataUserCompanie, {
                                  headers: {
                                    authorization: `Bearer ${data.refreshToken}`,
                                  },
                                })
                                .then(async () => {
                                  if (careerId === 0) {
                                    const messageEmail = {
                                      emails: [email.trim()],
                                      assunto:
                                        'Bem-vindo à iGrown! Comece a moldar sua jornada de carreira.',
                                      mensagem: `Caro ${name}, \n\nSeja bem-vindo à iGrown! Estamos muito felizes em recebê-lo(a) em nossa plataforma de desenvolvimento de carreira. Como um colaborador registrado, você está prestes a iniciar uma jornada única e emocionante de autodescoberta, crescimento e oportunidades. \nNa iGrown, acreditamos no seu potencial e estamos aqui para ajudá-lo(a) a acelerar sua carreira. Nossa plataforma foi projetada para fornecer insights valiosos sobre suas habilidades, agilidades de aprendizado, potencial e estilo cultural, permitindo que você descubra seu verdadeiro potencial e alcance suas metas profissionais.\nAqui estão algumas coisas que você poderá fazer na iGrown:\nTestes de Perfil Comportamental: Descubra suas habilidades e características comportamentais, compreenda suas preferências de trabalho e como você pode se desenvolver ainda mais.\nTeste Cultural: Identifique seu estilo cultural predominante e compreenda como você se encaixa na cultura organizacional, facilitando sua integração e alinhamento com a empresa.\nExplorar suas Âncoras de Carreira: Descubra o que é mais importante para você em sua jornada profissional e como suas âncoras de carreira podem orientar suas escolhas e motivações.\nA iGrown está aqui para apoiá-lo(a) em cada etapa de sua carreira. Estamos empenhados em fornecer a você uma experiência excepcional e auxiliá-lo(a) no desenvolvimento de suas habilidades e alcance de seus objetivos profissionais.\nAproveite ao máximo sua experiência na iGrown! Estamos entusiasmados por fazer parte da sua jornada de crescimento e sucesso.\nSeu sucesso é nossa prioridade. Estamos aqui para ajudá-lo(a) a alcançar grandes conquistas!\n\nAtenciosamente,\nEquipe iGrown`,
                                    };
                                    await api
                                      .post('/email/send', messageEmail, {
                                        headers: {
                                          authorization: `Bearer ${data.refreshToken}`,
                                        },
                                      })
                                      .then(async () => {
                                        history.push({
                                          pathname: '/enviarEmail',
                                          search: `?redirect=${encodeURIComponent(
                                            redirectUrl,
                                          )}`,
                                          state: {
                                            email: email.trim(),
                                          },
                                        });
                                      })
                                      .catch(() => {
                                        setMsg('Erro ao enviar os seus dados!');
                                        setError(true);
                                        setIsLoading(false);
                                        user.delete();
                                      });
                                  } else {
                                    const userData = {
                                      carreira_id: careerId,
                                    };

                                    await api
                                      .put(
                                        `/users/${dataUser.user.id}`,
                                        userData,
                                        {
                                          headers: {
                                            authorization: `Bearer ${data.refreshToken}`,
                                          },
                                        },
                                      )
                                      .then(async () => {
                                        const messageEmail = {
                                          emails: [email.trim()],
                                          assunto:
                                            'Bem-vindo à iGrown! Comece a moldar sua jornada de carreira.',
                                          mensagem: `Caro ${name}, \n\nSeja bem-vindo à iGrown! Estamos muito felizes em recebê-lo(a) em nossa plataforma de desenvolvimento de carreira. Como um colaborador registrado, você está prestes a iniciar uma jornada única e emocionante de autodescoberta, crescimento e oportunidades. \nNa iGrown, acreditamos no seu potencial e estamos aqui para ajudá-lo(a) a acelerar sua carreira. Nossa plataforma foi projetada para fornecer insights valiosos sobre suas habilidades, agilidades de aprendizado, potencial e estilo cultural, permitindo que você descubra seu verdadeiro potencial e alcance suas metas profissionais.\nAqui estão algumas coisas que você poderá fazer na iGrown:\nTestes de Perfil Comportamental: Descubra suas habilidades e características comportamentais, compreenda suas preferências de trabalho e como você pode se desenvolver ainda mais.\nTeste Cultural: Identifique seu estilo cultural predominante e compreenda como você se encaixa na cultura organizacional, facilitando sua integração e alinhamento com a empresa.\nExplorar suas Âncoras de Carreira: Descubra o que é mais importante para você em sua jornada profissional e como suas âncoras de carreira podem orientar suas escolhas e motivações.\nA iGrown está aqui para apoiá-lo(a) em cada etapa de sua carreira. Estamos empenhados em fornecer a você uma experiência excepcional e auxiliá-lo(a) no desenvolvimento de suas habilidades e alcance de seus objetivos profissionais.\nAproveite ao máximo sua experiência na iGrown! Estamos entusiasmados por fazer parte da sua jornada de crescimento e sucesso.\nSeu sucesso é nossa prioridade. Estamos aqui para ajudá-lo(a) a alcançar grandes conquistas!\n\nAtenciosamente,\nEquipe iGrown`,
                                        };
                                        await api
                                          .post('/email/send', messageEmail, {
                                            headers: {
                                              authorization: `Bearer ${data.refreshToken}`,
                                            },
                                          })
                                          .then(async () => {
                                            history.push({
                                              pathname: '/enviarEmail',
                                              search: `?redirect=${encodeURIComponent(
                                                redirectUrl,
                                              )}`,
                                              state: {
                                                email: email.trim(),
                                              },
                                            });
                                          })
                                          .catch(() => {
                                            setMsg(
                                              'Erro ao enviar os seus dados!',
                                            );
                                            setError(true);
                                            setIsLoading(false);
                                            user.delete();
                                          });
                                      })
                                      .catch(() => {
                                        setMsg('Erro ao enviar os seus dados!');
                                        setError(true);
                                        setIsLoading(false);
                                        user.delete();
                                      });
                                  }
                                })
                                .catch(() => {
                                  setMsg('Erro ao enviar os seus dados!');
                                  setError(true);
                                  setIsLoading(false);
                                  user.delete();
                                });
                            } else {
                              const messageEmail = {
                                emails: [email.trim()],
                                assunto:
                                  'Bem-vindo à iGrown! Comece a moldar sua jornada de carreira.',
                                mensagem: `Caro ${name}, \n\nSeja bem-vindo à iGrown! Estamos muito felizes em recebê-lo(a) em nossa plataforma de desenvolvimento de carreira. Como um colaborador registrado, você está prestes a iniciar uma jornada única e emocionante de autodescoberta, crescimento e oportunidades. \nNa iGrown, acreditamos no seu potencial e estamos aqui para ajudá-lo(a) a acelerar sua carreira. Nossa plataforma foi projetada para fornecer insights valiosos sobre suas habilidades, agilidades de aprendizado, potencial e estilo cultural, permitindo que você descubra seu verdadeiro potencial e alcance suas metas profissionais.\nAqui estão algumas coisas que você poderá fazer na iGrown:\nTestes de Perfil Comportamental: Descubra suas habilidades e características comportamentais, compreenda suas preferências de trabalho e como você pode se desenvolver ainda mais.\nTeste Cultural: Identifique seu estilo cultural predominante e compreenda como você se encaixa na cultura organizacional, facilitando sua integração e alinhamento com a empresa.\nExplorar suas Âncoras de Carreira: Descubra o que é mais importante para você em sua jornada profissional e como suas âncoras de carreira podem orientar suas escolhas e motivações.\nA iGrown está aqui para apoiá-lo(a) em cada etapa de sua carreira. Estamos empenhados em fornecer a você uma experiência excepcional e auxiliá-lo(a) no desenvolvimento de suas habilidades e alcance de seus objetivos profissionais.\nAproveite ao máximo sua experiência na iGrown! Estamos entusiasmados por fazer parte da sua jornada de crescimento e sucesso.\nSeu sucesso é nossa prioridade. Estamos aqui para ajudá-lo(a) a alcançar grandes conquistas!\n\nAtenciosamente,\nEquipe iGrown`,
                              };
                              await api
                                .post('/email/send', messageEmail, {
                                  headers: {
                                    authorization: `Bearer ${data.refreshToken}`,
                                  },
                                })
                                .then(async () => {
                                  history.push({
                                    pathname: '/enviarEmail',
                                    search: `?redirect=${encodeURIComponent(
                                      redirectUrl,
                                    )}`,
                                    state: {
                                      email: email.trim(),
                                    },
                                  });
                                })
                                .catch(() => {
                                  setMsg('Erro ao enviar os seus dados!');
                                  setError(true);
                                  setIsLoading(false);
                                  user.delete();
                                });
                            }
                          })
                          .catch(() => {
                            setMsg('Erro ao enviar os seus dados!');
                            setError(true);
                            setIsLoading(false);
                            user.delete();
                          });
                      })
                      .catch(() => {
                        setMsg('Erro ao enviar os seus dados!');
                        setError(true);
                        setIsLoading(false);
                        user.delete();
                      });
                  })
                  .catch(() => {
                    setMsg('Erro ao enviar os seus dados!');
                    setError(true);
                    setIsLoading(false);
                    user.delete();
                  });
              })
              .catch(() => {
                setMsg('Erro ao enviar os seus dados!');
                setError(true);
                setIsLoading(false);
                user.delete();
              });
          })
          .catch(firebaseError => {
            if (firebaseError.code === 'auth/email-already-in-use')
              setMsg(
                'E-mail já cadastrado! Tente fazer login ou clique em "Esqueceu a senha?" na tela de login',
              );
            else if (firebaseError.code === 'auth/weak-password')
              setMsg('A senha deve conter no mínimo 6 caracteres');
            else {
              setMsg(
                `Erro ao enviar os seus dados! Entre em contato conosco e informe o erro: ${firebaseError.code}`,
              );
            }
            setError(true);
            setIsLoading(false);
          });
      } else {
        setMsg('Data inválida! Insira uma data válida!');
        setError(true);
        setIsLoading(false);
      }
    } else {
      setMsg('É necessário preeencher todos os campos!');
      setError(true);
      setIsLoading(false);
    }
  };

  const getCompanies = async () => {
    await api
      .get('/companies', {})
      .then(async response => {
        const data = response.data.response;
        const json = [];
        await data.forEach(item => {
          json.push({ id: item.id, label: item.nome_fantasia });
        });
        json.push({ id: 0, label: 'Não encontrei minha empresa' });
        setCompanies(json);
      })
      .catch(() => {
        setCompanies(Companies);
        setError(true);
      });
  };

  const getCareer = async company => {
    await api
      .get(`/companies/carreiras/${company?.id}`, {})
      .then(async response => {
        const json = [];
        const { data } = response;
        await data.forEach(item => {
          json.push({ id: item.id, label: item.nome });
        });
        json.push({ id: 0, label: 'Não encontrei minha carreira' });
        setCareer(json);
      })
      .catch(() => {
        setError(true);
      });
  };

  const onChangeName = e => {
    setName(e.target.value);
    setMsg('');
    setError(false);
  };

  const onChangePassword = e => {
    setPassword(e.target.value);
    setMsg('');
    setError(false);
  };

  const onChangePasswordConfirm = e => {
    setPasswordConfirm(e.target.value);
    setMsg('');
    setError(false);
  };

  const onChangePhone = e => {
    setPhone(phoneMask(e.target.value));
    setMsg('');
    setError(false);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
    setMsg('');
    setError(false);
  };

  const onChangeDate = e => {
    const newDate = dateMask(e.target.value);
    setDate(newDate);
    setMsg('');
    setError(false);
  };

  const onChangeGenre = e => {
    const newGenre = e.target.value;
    setGenre(newGenre);
    setMsg('');
    setError(false);
  };

  const onChangeOccupation = e => {
    const newOccupation = e.target.value;
    if (newOccupation === 1) {
      setStatusOccupation(true);
      setOccupation(newOccupation);
    } else {
      setStatusOccupation(false);
      setOccupation(newOccupation);
      setCompanie('');
    }

    setMsg('');
    setError(false);
  };

  const onChangeCompanie = useCallback(
    (_event, value) => {
      setStatusCompanie(value === 0 && statusOccupation);
      setStatusCareer(value !== 0 && statusOccupation);
      setCompanie(value);
      setCompanieId(value?.id);
      setMsg('');
      setError(false);
      getCareer(value);
    },
    [statusOccupation],
  );

  const onChangeCarrer = (event, value) => {
    if (value) setCareerId(value.id);
  };

  const onChangeNameCompanie = e => {
    setNameCompanie(e.target.value);
    setMsg('');
    setError(false);
  };

  useEffect(() => {
    getCompanies();
    setWidth(window.screen.width);
  }, [width]);

  useEffect(() => {
    const unmaskedCpf = unmaskCpf(cpf);
    if (unmaskedCpf.length === 11 && !validateCpf(unmaskedCpf)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [cpf]);

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogin width={width} />
            <Container>
              <Header>
                <a href="/login">
                  <Logo src={logo} alt="iGrown" />
                </a>
              </Header>
              <Label>Continue o seu cadastro</Label>
              <Card>
                <Group top="30px">
                  <FormControl
                    sx={{
                      m: 1.2,
                      width: '80%',
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                    }}
                  >
                    <TextField
                      label="Nome completo"
                      variant="outlined"
                      size="small"
                      value={name}
                      onChange={onChangeName}
                      color="info"
                      disabled={false}
                    />
                  </FormControl>
                  <Row>
                    <FormControl
                      sx={{
                        m: 1.2,
                        width: '39%',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                      }}
                    >
                      <TextField
                        label="Senha"
                        variant="outlined"
                        size="small"
                        color="info"
                        onChange={onChangePassword}
                        value={password}
                        type="password"
                        disabled={false}
                      />
                    </FormControl>
                    <FormControl
                      sx={{
                        m: 1.2,
                        width: '39%',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                      }}
                    >
                      <TextField
                        label="Confirmar senha"
                        variant="outlined"
                        size="small"
                        color="info"
                        onChange={onChangePasswordConfirm}
                        type="password"
                        value={passwordConfirm}
                        disabled={false}
                      />
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl
                      sx={{
                        m: 1.2,
                        width: '39%',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                      }}
                    >
                      <TextField
                        label="E-mail"
                        variant="outlined"
                        value={email}
                        onChange={onChangeEmail}
                        size="small"
                        color="info"
                        disabled={false}
                      />
                    </FormControl>
                    <FormControl
                      sx={{
                        m: 1.2,
                        width: '39%',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                      }}
                    >
                      <TextField
                        label="Celular"
                        variant="outlined"
                        size="small"
                        color="info"
                        onChange={onChangePhone}
                        value={phone}
                        disabled={false}
                      />
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl
                      sx={{
                        m: 1.2,
                        width: '39%',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                      }}
                    >
                      <TextField
                        label="Data de nascimento"
                        variant="outlined"
                        size="small"
                        onChange={onChangeDate}
                        value={date}
                        color="info"
                        disabled={false}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1.2, width: '39%' }}>
                      <InputLabel id="genre">Gênero</InputLabel>
                      <Select
                        labelId="genre"
                        id="genre"
                        variant="outlined"
                        value={genre}
                        size="small"
                        color="info"
                        onChange={onChangeGenre}
                        disabled={false}
                        input={<OutlinedInput label="Gênero" />}
                      >
                        <MenuItem value={1}>Feminino</MenuItem>
                        <MenuItem value={2}>Masculino</MenuItem>
                        <MenuItem value={3}>Prefiro não informar</MenuItem>
                      </Select>
                    </FormControl>
                  </Row>
                  <FormControl sx={{ m: 1.2, width: '80%' }}>
                    <InputLabel id="occupation">Ocupação principal</InputLabel>
                    <Select
                      labelId="occupation"
                      id="occupation"
                      variant="outlined"
                      value={occupation}
                      size="small"
                      color="info"
                      onChange={onChangeOccupation}
                      disabled={false}
                      input={<OutlinedInput label="Ocupação principal" />}
                    >
                      <MenuItem value={1}>Trabalho</MenuItem>
                      <MenuItem value={2}>Estudo</MenuItem>
                      <MenuItem value={3}>Em busca de recolocação</MenuItem>
                      <MenuItem value={4}>Aposentado</MenuItem>
                      <MenuItem value={5}>Outros</MenuItem>
                    </Select>
                  </FormControl>
                </Group>
                {statusOccupation && (
                  <FormControl sx={{ m: 1.2, width: '80%' }}>
                    <Stack spacing={2}>
                      <Autocomplete
                        disablePortal
                        onChange={(event, value) =>
                          onChangeCompanie(event, value)
                        }
                        id="searchCompanie"
                        open={open}
                        onInputChange={(_, value) => {
                          if (value.length === 0) {
                            if (open) setOpen(false);
                          } else if (!open) setOpen(true);
                        }}
                        onClose={() => setOpen(false)}
                        options={companies}
                        renderInput={params => (
                          <TextField
                            value={params}
                            {...params}
                            label="Procure a sua empresa"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </FormControl>
                )}
                {statusOccupation && statusCareer && (
                  <>
                    <FormControl sx={{ m: 1.2, width: '80%' }}>
                      <Stack spacing={2}>
                        <Autocomplete
                          disablePortal
                          onChange={(event, value) =>
                            onChangeCarrer(event, value)
                          }
                          id="searchCareer"
                          onClose={() => setOpenCareer(false)}
                          options={career}
                          renderInput={params => (
                            <TextField
                              value={params}
                              {...params}
                              label="Procure a sua carreira"
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </FormControl>
                    {COMPANIES_WITH_REQUIRED_CPF.includes(companie.id) && (
                      <FormControl sx={{ m: 1.2, width: '80%' }}>
                        {' '}
                        <InputMask
                          mask="999.999.999-99"
                          maskChar="_"
                          value={cpf}
                          onChange={e => setCpf(e.target.value)}
                        >
                          {() => (
                            <TextField
                              fullWidth
                              sx={{ marginTop: 1 }}
                              label="CPF"
                              id="cpf-input"
                            />
                          )}
                        </InputMask>
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '13px',
                            paddingTop: 1,
                            textAlign: 'left',
                            color: colors.alertRed,
                          }}
                        >
                          {invalid && 'CPF inválido'}
                        </Typography>
                      </FormControl>
                    )}
                  </>
                )}
                {statusCompanie && statusOccupation && (
                  <FormControl
                    sx={{
                      m: 1.2,
                      width: '80%',
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                    }}
                  >
                    <TextField
                      label="Nome da sua empresa"
                      variant="outlined"
                      value={nameCompanie}
                      onChange={onChangeNameCompanie}
                      size="small"
                      color="info"
                      disabled={false}
                    />
                  </FormControl>
                )}
                <Group>
                  <Checkbox
                    checked={checkbox}
                    onChange={() => setCheckbox(!checkbox)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Description color={colors.gray3} weight="400" font="12px">
                    Autorizo o compartilhamento dos meus dados com a iGrown.
                  </Description>
                </Group>
                <ErrorMessage color={error ? colors.alertRed : colors.darkBlue}>
                  {msg}
                </ErrorMessage>
                <Group>
                  <Button
                    disabled={!checkbox || isLoading}
                    type="button"
                    width={width > 530 ? '600px' : '320px'}
                    hoverColor={colors.lightBlue}
                    text="Avançar"
                    isLoading={isLoading}
                    onClick={onNext}
                  />
                </Group>
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider>
  );
}

export default NaturalPerson;
