import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
  CircularProgress,
} from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import HardskillModal from '../../components/HardskillModal';
import StoreContext from '../../components/Store/Context';
import ErrorModal from '../../components/ErrorModal';
import api from '../../api';
import CheckModal from '../../components/CheckModal';

import {
  Container,
  SubTitle,
  Title,
  Group,
  Line,
  DescriptionData,
  DescriptionGroup,
  Row,
  ItemDescription,
} from './styles';
import { userXpLevels } from '../../utils/xpLevels';

function CalibrateHardSkillsCareer() {
  const { token } = useContext(StoreContext);
  const [dataCareer, setDataCareer] = useState('');
  const [list, setList] = useState([]);
  const [json, setJson] = useState([]);
  const [width, setWidth] = useState(window.screen.width);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [userId, setUserId] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = useState('');

  const onFinish = async () => {
    if (json.length > 0) {
      const data = {
        data: json,
      };
      await api
        .post('/user-hardskill', data, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async () => {
          setModal(true);
        })
        .catch(() => {
          setError(true);
        });
    } else {
      setMsg('Selecione todas as opções!');
      setAlert(true);
    }
  };

  const onHome = async () => {
    history.push({
      pathname: '/',
    });
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const getHardskill = useCallback(
    async hards => {
      if (!hards || hards.length === 0) {
        history.push('/');
        return;
      }
      await api
        .get('/hardskill', {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response.data;
          console.log('Hardskills data:', hards);
          console.log('API response:', data);
          const l = hards
            .map(item => {
              const arr = data.find(i => i.id === item.id) || null;
              return arr;
            })
            .filter(item => item !== null && item.tipo);
          setList(l);
          setIsVisible(true);
        })
        .catch(() => {
          setError(true);
        });
    },
    [token, history],
  );

  const getCareer = useCallback(
    async id => {
      await api
        .get(`/carreiras/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response;
          setDataCareer(data);
          if (!data[0]?.persona?.persona?.hard_skills) {
            history.push('/');
            return;
          }
          getHardskill(data[0].persona.persona.hard_skills);
        })
        .catch(() => {
          setError(true);
        });
    },
    [getHardskill, token, history],
  );

  const getUsers = useCallback(async () => {
    await api
      .get('/users?status=S', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const data = response.data.users;
        const user = data.filter(item => {
          return item.email === token.email;
        });
        setUserId(user[0].id);
        getCareer(user[0].carreira_id);
      })
      .catch(() => {
        setError(true);
      });
  }, [getCareer, token]);

  const onCloseModal = async () => {
    setModal(false);
  };

  const mountJson = async (e, hardskillId) => {
    const { value } = e.target;
    const arr = json.filter(item => {
      return item.hard_skill_id !== hardskillId;
    });
    arr.push({
      user_id: userId,
      hard_skill_id: hardskillId,
      valor: value,
    });
    setJson(arr);
  };

  const onCloseError = () => {
    setError(false);
  };

  useEffect(() => {
    getUsers();
    setWidth(window.screen.width);
  }, [getUsers, json, width]);

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <CheckModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      <HardskillModal
        isOpen={modal}
        calibrate
        onRequestClose={onCloseModal}
        onHome={onHome}
      />
      {isVisible ? (
        <>
          <Group>
            <Title>Auto-avaliação de competências da carreira</Title>
            <SubTitle font="19px" color={colors.lightBlue}>
              {dataCareer.length > 0 ? dataCareer[0].nome : '--'}
            </SubTitle>
            {list.length > 0 ? (
              <DescriptionGroup>
                <DescriptionData font="14px" weight="400">
                  {dataCareer.length > 0
                    ? `Utilize o formulário abaixo para registrar a sua auto avaliação sobre as suas competências técnicas relacionadas à posição ${dataCareer[0].nome}.`
                    : ''}
                </DescriptionData>
              </DescriptionGroup>
            ) : (
              <>
                <DescriptionGroup>
                  <DescriptionData align="center" font="14px" weight="400">
                    Esta carreira ainda não possui Hard Skills (habilidades
                    técnicas) cadastradas!
                  </DescriptionData>
                </DescriptionGroup>
                <Row top="0%">
                  <Button
                    type="button"
                    width="400px"
                    fontSize="13px"
                    text="Finalizar"
                    hoverColor={colors.lightBlue}
                    color={colors.white}
                    bgColor={colors.lightBlue}
                    onClick={onHome}
                  />
                </Row>
              </>
            )}
            <Line />
          </Group>
          {list.length > 0 &&
            list.filter(i => i && i.tipo).map(i => {
              if (i.tipo === 'valor') {
                return (
                  <Group>
                    <ItemDescription>{i.nome}</ItemDescription>
                    <DescriptionGroup>
                      <FormControl
                        sx={{ m: 1, width: '95%', background: colors.white }}
                      >
                        <InputLabel id="value">Nível de experiência</InputLabel>
                        <Select
                          labelId="value"
                          id="value"
                          InputLabelProps={{ shrink: true, required: true }}
                          size="small"
                          color="info"
                          onChange={value => mountJson(value, i.id)}
                          disabled={false}
                          input={<OutlinedInput label="Nível de experiência" />}
                        >
                          {userXpLevels.map((level, index) => (
                            <MenuItem value={index}>
                              {index}: {level}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DescriptionGroup>
                    <Line />
                  </Group>
                );
              }
              if (i.tipo === 'select') {
                return (
                  <Group>
                    <ItemDescription>{i.nome}</ItemDescription>
                    <DescriptionGroup>
                      <FormControl
                        sx={{ m: 1, width: '95%', background: colors.white }}
                      >
                        <InputLabel id="value">Resposta</InputLabel>
                        <Select
                          labelId="value"
                          id="value"
                          InputLabelProps={{ shrink: true, required: true }}
                          size="small"
                          color="info"
                          onChange={value => mountJson(value, i.id)}
                          disabled={false}
                          input={<OutlinedInput label="Resposta" />}
                        >
                          <MenuItem value="S">Sim</MenuItem>
                          <MenuItem value="N">Não</MenuItem>
                        </Select>
                      </FormControl>
                    </DescriptionGroup>
                    <Line />
                  </Group>
                );
              }
              return (
                <Group>
                  <ItemDescription>{i.nome}</ItemDescription>
                  <DescriptionGroup>
                    <FormControl
                      sx={{ m: 1, width: '95%', background: colors.white }}
                    >
                      <InputLabel id="job">Nível</InputLabel>
                      <Select
                        labelId="job"
                        id="job"
                        InputLabelProps={{ shrink: true, required: true }}
                        size="small"
                        color="info"
                        onChange={value => mountJson(value, i.id)}
                        disabled={false}
                        input={<OutlinedInput label="Nível" />}
                      >
                        <MenuItem value="Básico">Básico</MenuItem>
                        <MenuItem value="Intermediário">Intermediário</MenuItem>
                        <MenuItem value="Avançado">Avançado</MenuItem>
                        <MenuItem value="Fluente">Fluente</MenuItem>
                      </Select>
                    </FormControl>
                  </DescriptionGroup>
                  <Line />
                </Group>
              );
            })}
          {list.length > 0 && (
            <Group>
              <Row top="0%">
                <Button
                  type="button"
                  width="400px"
                  fontSize="13px"
                  text="Salvar"
                  hoverColor={colors.lightBlue}
                  color={colors.white}
                  bgColor={colors.lightBlue}
                  onClick={onFinish}
                />
              </Row>
            </Group>
          )}
        </>
      ) : (
        <Group>
          <CircularProgress />
        </Group>
      )}
    </Container>
  );
}

export default CalibrateHardSkillsCareer;
